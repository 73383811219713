
.App-header h1 {
    color: rgb(255, 255, 255);
    font-size: 75px;
}

.Content-box {
    background-color: rgba(255,255,255,0.05);
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    display: block;
    margin: 0 auto;
    color: #fff;
}

.Content-box h2 {
    font-size: 30px;
    margin: 0;
    font-weight: bold;
}

#tasks-list {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 5px;
}