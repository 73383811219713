.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  transition: .3s ease all;
  visibility: visible;
  opacity: 1;
}
.modal .modal-main {
  position: fixed;
  background: #fff;
  width: 500px;
  height: 300px;
  margin: auto;  
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #094345;
  transition: .3s ease all;
  border-radius: 3%;
}

.modal.hidden {
  opacity: 0;
  visibility: hidden;
  transition: .3s ease all, .3s 0s visibility;
}

.modal.hidden .modal-main {
  transform: scale(0.9);
}

.modal .close {
  position: absolute;
  right: -5px;
  top: -5px;
  border-radius: 50px;
  font-size: 1.5em;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

#user-list {
  width: 100%;
  border-collapse: separate; 
  border-spacing: 0 5px;
  text-align: left;
  margin: 30px;
}